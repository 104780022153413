import { loadReports } from './reports';
import request from "@/api/http";
import {requestFile} from "@/api/http";

export function init () {
    return request('/api/v1/init/')
}

export function logOutSession() {
    return request('/api/v1/logout/')
}

export function exportParts(data) {
    let url = '/api/v1/parts/export/';
    let formData = new FormData();
    if (data.parts) {
        for (var i = 0; i < data.parts.length; i++) {
            for (var key in data.parts[i]) {
                formData.append(`parts[${i}][${key}]`, data.parts[i][key]);
            }
        }
    }
    return requestFile(url, {
        method : 'POST',
        body : formData,
    });
}

export function cartRemove (id) {
    let data = new FormData();
    data.append('_method', 'DELETE');
    // return request(`/api/v1/cart/${id}/remove/`, { method : 'POST', body : data, });
    data.append('carts[]', id);
    return request(`/api/v1/cart/remove/`, { method : 'POST', body : data, });
}

export function cartRemoveMultiple (idList = []) {
    let data = new FormData();
    data.append('_method', 'DELETE');
    for (var i = 0; i < idList.length; i++) {
        data.append('carts[]', idList[i]);
    }
    return request(`/api/v1/cart/remove/`, { method : 'POST', body : data, });
}

export function loadCostTypes () {
    return request(`/api/v1/costtypes/`);
}

export function loadCities () {
    return request(`/api/v1/cities/`);
}

export function loadCategories (params = {}) {
    let url = '/api/v1/categories/?';
    for (var key in params) {
        if (params[key]) {
            url += `${key}=${params[key]}&`;
        }
    }
    return request(url);
}

export function loadNomenclature (params = {}) {
    let url = '/api/v1/nomenclatures/?';
    for (var key in params) {
        if (params[key]) {
            url += `${key}=${params[key]}&`;
        }
    }
    return request(url);
}

export function loadNomenclatureData () {
    return request(`/api/v1/nomenclatures/data/`);
}

export function saveNomenclature (id ='', data) {
    let url = '/api/v1/nomenclatures/';
    if (+id) {
        url += `${id}/`;
        data.append('_method', 'PUT');
    }
    return request(url, {
        method : 'POST',
        body : data,
    });
}

export function removeNomenclature (id) {
    let data = new FormData();
    data.append('_method', 'DELETE');
    return request(`/api/v1/nomenclatures/${id}/`, {
        method : 'POST',
        body : data,
    });
}

export function loadPartData () {
    return request(`/api/v1/parts/data/`);
}

export function loadPart (id) {
    return request(`/api/v1/parts/${id}/`);
}

export function loadParts (params) {
    let url = '/api/v1/parts/?';
    for (var key in params) {
        if (params[key]) {
            url += `${key}=${params[key]}&`;
        }
    }
    return request(url);
}

export function uploadPartsFile (file) {
    let data = new FormData();
    data.append('file', file);
    let url = '/api/v1/parts/import/';
    return request(url, {
        method : 'POST',
        body : data,
    });
}

export function loadLotParts (lotId, params) {
    let url = `/api/v1/lots/${lotId}/parts/?`;
    for (var key in params) {
        if (params[key]) {
            url += `${key}=${params[key]}&`;
        }
    }
    return request(url);
}

export function deletePart (id, params) {
    let data = new FormData();
    data.append('_method', 'DELETE');
    for (var key in params) {
        data.append(key, params[key]);
    }
    return request(`/api/v1/parts/${id}/`, {
        method : 'POST',
        body : data,
    });
}

export function returnPart (id, params) {
    let data = new FormData();
    for (var key in params) {
        data.append(key, params[key]);
    }
    return request(`/api/v1/parts/${id}/return/`, {
        method : 'POST',
        body : data,
    });
}

export function loadLot (id) {
    return request(`/api/v1/lots/${id}/`);
}

export function loadLotData () {
    let url = '/api/v1/lots/data/';
    return request(url);
}

export function loadLots (params) {
    let url = '/api/v1/lots/?';
    for (var key in params) {
        if (params[key]) {
            url += `${key}=${params[key]}&`;
        }
    }
    return request(url);
}

export function loadUserLots (investorId, params) {
    let url = `/api/v1/users/${investorId}/lots/?`;
    for (var key in params) {
        if (params[key]) {
            url += `${key}=${params[key]}&`;
        }
    }
    return request(url);
}

export function loadUserIncomeHistory (investorId, params) {
    let url = `/api/v1/users/${investorId}/incomes/?`;
    for (var key in params) {
        if (params[key]) {
            url += `${key}=${params[key]}&`;
        }
    }
    return request(url);
}

export function loadBrands () {
    return request(`/api/v1/marks/`);
}

export function loadMarkModels (markId) {
    let url = '/api/v1/marks/models/?mark_id='+markId;
    return request(url);
}

export function loadManufacturerModels (manufacturer) {
    let url = '/api/v1/manufacturers/models/?manufacturer='+manufacturer;
    return request(url);
}

export function savePart (id = null, data) {
    let url = '/api/v1/parts/';
    let method = 'POST';
    if (+id) {
        url += `${id}/`;
        data.append('_method', 'PUT');
    }
    return request(url, {
        method,
        body : data,
    });
}

export function loadUsers (params) {
    let url = '/api/v1/users/?';
    for (var key in params) {
        url += `${key}=${params[key]}&`;
    }
    url = url.substr( 0, url.length - 1 )
    return request(url);
}

export function loadUsersData () {
    let url = '/api/v1/users/data/';
    return request(url);
}

export function loadUserData (id) {
    return request(`/api/v1/users/${id}/`);
}

export function restoreUser (id) {
    let data = new FormData();
    data.append('_method', 'PATCH');
    return request(`/api/v1/users/${id}/`, {
        method : 'POST',
        body : data,
    });
}

export function removeUser (id) {
    let data = new FormData();
    data.append('_method', 'DELETE');
    return request(`/api/v1/users/${id}/`, {
        method : 'POST',
        body : data,
    });
}

export function saveUser (id, data) {
    let url = '/api/v1/users/';
    if (+id) {
        url += `${id}/`;
        data.append('_method', 'PUT');
    }
    return request(url, {
        method : 'POST',
        body : data,
    });
}

export function saveInvestor (id, data) {
    let url = '/api/v1/users/';
    if (+id) {
        url += `${id}/`;
        data.append('_method', 'PUT');
    }
    return request(url, {
        method : 'POST',
        body : data,
    });
}

export function loadInvestorsList (params) {
    let url = '/api/v1/users/?';
    for (var key in params) {
        url += `${key}=${params[key]}&`;
    }
    return request(url);
}

export function loadInvestorData (id) {
    return request(`/api/v1/users/${id}/`);
}

export function restoreInvestor (id) {
    let data = new FormData();
    data.append('_method', 'PATCH');
    return request(`/api/v1/users/${id}/`, {
        method : 'POST',
        body : data,
    });
}

export function removeInvestor (id) {
    let data = new FormData();
    data.append('_method', 'DELETE');
    return request(`/api/v1/users/${id}/`, {
        method : 'POST',
        body : data,
    });
}

export function loadEmployeesList (params) {
    let url = '/api/v1/employees/?';
    for (var key in params) {
        url += `${key}=${params[key]}&`;
    }
    return request(url);
}

export function loadEmployeeData (id) {
    return request(`/api/v1/employees/${id}/`);
}

export function restoreEmployee (id) {
    let data = new FormData();
    data.append('_method', 'PATCH');
    return request(`/api/v1/employees/${id}/`, {
        method : 'POST',
        body : data,
    });
}

export function removeEmployee (id) {
    let data = new FormData();
    data.append('_method', 'DELETE');
    return request(`/api/v1/employees/${id}/`, {
        method : 'POST',
        body : data,
    });
}

export function postTransactionsTransfer (data) {
    return request(`/api/v1/transactions/transfer/`, {
        method : 'POST',
        body : data,
    });
}

export function saveEmployee (id, data) {
    let url = '/api/v1/employees/';
    if (+id) {
        url += `${id}/`;
        data.append('_method', 'PUT');
    }
    return request(url, {
        method : 'POST',
        body : data,
    });
}

export function deleteLot (lotId) {
    let data = new FormData();
    data.append('_method', 'DELETE');
    return request(`/api/v1/lots/${lotId}/`, {
        method : 'POST',
        body : data,
    });
}

export function createBranches (data) {
    return request('/api/v1/branches/', {
        method : 'POST',
        body : data,
    });
}

export function loadBranches (params) {
    let url = '/api/v1/branches/?';
    for (var key in params) {
        url += `${key}=${params[key]}&`;
    }
    return request(url);
}

// export function loadBranchesStats (params) {
//     let url = '/api/v1/branches/stats/?';
//     for (var key in params) {
//         url += `${key}=${params[key]}&`;
//     }
//     return request(url);
// }

export function loadTransactions (type = '', params = {}) {
    let url = '/api/v1/transactions/';
    if (type) {
        url += type + '/';
    }
    url += '?';
    for (var key in params) {
        url += `${key}=${params[key]}&`;
    }
    return request(url);
}

export function loadUserTransactions (investorId, params = {}) {
    let url = `/api/v1/users/${investorId}/transactions/? `;
    for (var key in params) {
        url += `${key}=${params[key]}&`;
    }
    return request(url);
}

export function transactionConfirm (id) {
    let url = `/api/v1/transactions/${id}/confirm/`;
    return request(url, { method : 'POST' });
}

export function transactionReject (id) {
    let url = `/api/v1/transactions/${id}/reject/`;
    return request(url, { method : 'POST' });
}

export function transactionSend (data, transactionType = 'deposit') {
    return request(`/api/v1/transactions/${transactionType}/`, {
        method : 'POST',
        body : data,
    });
}

export function transactionSendDeposit (data) {
    return request('/api/v1/transactions/deposit/', {
        method : 'POST',
        body : data,
    });
}

export function transactionSendWithdrawal (data) {
    return request('/api/v1/transactions/withdrawal/', {
        method : 'POST',
        body : data,
    });
}

export function transactionSendExpense (data) {
    return request('/api/v1/transactions/expense/', {
        method : 'POST',
        body : data,
    });
}

export function transactionSendInvestment (data) {
    return request('/api/v1/transactions/investment/', {
        method : 'POST',
        body : data,
    });
}

export function transactionsSale (data) {
    return request('/api/v1/transactions/sale/', {
        method : 'POST',
        body : data,
    });
}

export function transactionsRefund (data) {
    return request('/api/v1/transactions/refund/', {
        method : 'POST',
        body : data,
    });
}

export function transactionsPrepayment (data) {
    return request('/api/v1/transactions/prepayment/', {
        method : 'POST',
        body : data,
    });
}

export function loadTransactionsData (transactionType) {
    return request(`/api/v1/transactions/${transactionType}/data/`);
}

export function addToCart (data) {
    let formData = new FormData();
    if (data.parts) {
        for (var i = 0; i < data.parts.length; i++) {
            for (var key in data.parts[i]) {
                formData.append(`parts[${i}][${key}]`, data.parts[i][key]);
            }
        }
    }
    return request('/api/v1/cart/', {
        method : 'POST',
        body : formData,
    });
}

export function loadCart (params) {
    let url = '/api/v1/cart/?';
    for (var key in params) {
        url += `${key}=${params[key]}&`;
    }
    return request(url);
}

export function loadMoneytypes () {
    return request('/api/v1/moneytypes/');
}

export function loadManufacturers () {
    return request('/api/v1/manufacturers/');
}

export function loadRoles () {
    return request('/api/v1/roles/');
}

export function saveRole (id = null, formData) {
    let url = '/api/v1/roles/';
    if (+id) {
        url += id + '/';
        formData.append('_method', 'PUT');
    }
    return request(url, {
        method : 'POST',
        body : formData,
    });
}

export function deleteRole (id) {
    let data = new FormData();
    data.append('_method', 'DELETE');
    return request(`/api/v1/roles/${id}/`, { method : 'POST', body : data, });
}

export function loadPermissions () {
    return request('/api/v1/permissions/');
}

export function loadLogistics () {
    return request('/api/v1/logistics/');
}

export function loadLogistic (id) {
    return request(`/api/v1/logistics/${id}/`);
}

export function loadLogisticsData () {
    return request('/api/v1/logistics/data/');
}

export function logisticConfirm (id) {
    let url = `/api/v1/logistics/${id}/confirm/`;
    return request(url, { method : 'POST' });
}

export function logisticCancel (id) {
    let url = `/api/v1/logistics/${id}/cancel/`;
    return request(url, { method : 'POST' });
}

export function loadOrders () {
    return request('/api/v1/orders/');
}

export function loadOrdersData () {
    return request('/api/v1/orders/data/');
}

export function loadOrder (id) {
    return request(`/api/v1/orders/${id}/`);
}

export function orderConfirm (id, { moneytypeId, discount }) {
    let url = `/api/v1/orders/${id}/confirm/`;
    let data = new FormData();
    data.append('moneytype_id', moneytypeId);
    data.append('discount', discount);
    return request(url, { method : 'POST', body : data });
}

export function orderReject (id) {
    let url = `/api/v1/orders/${id}/reject/`;
    return request(url, { method : 'POST' });
}

export function orderCancel (id) {
    let url = `/api/v1/orders/${id}/cancel/`;
    return request(url, { method : 'POST' });
}

export function saveOrder (data) {
    let url = `/api/v1/orders/`;
    return request(url, { method : 'POST', body : data, });
}

// Reports
export {
	loadReports
}
