<template>
  <div class="app" :class="appClass">
    <header>
      <a href="#" class="logo"></a>
      <template v-if="isAuthorized">
        <a class="link link--profile" href="#" @click.prevent="profileDropdownVisible = !profileDropdownVisible">
          {{
            [$store.state.user.name, $store.state.user.lastname]
              .filter((v) => v)
              .join(" ")
          }}
        </a>
        <div class="profile-dropdown" v-if="profileDropdownVisible">
          <ul class="profile-dropdown-menu" @click="profileDropdownVisible = false">
            <li class="profile-dropdown-menu__item profile-dropdown-menu__item--profile">
              <router-link :to="{ name: 'profile', params: { id: userId } }">Профиль</router-link>
            </li>
            <li class="profile-dropdown-menu__item profile-dropdown-menu__item--settings">
              <router-link :to="{ name: 'branch-edit' }">Настройки</router-link>
            </li>
            <li class="profile-dropdown-menu__item profile-dropdown-menu__item--administration">
              <router-link :to="{ name: 'administration' }">Администрирование</router-link>
            </li>
            <li class="profile-dropdown-menu__item profile-dropdown-menu__item--nomenclature-list">
              <router-link :to="{ name: 'nomenclature-list' }">Редактор номенклатуры</router-link>
            </li>
            <li class="profile-dropdown-menu__item profile-dropdown-menu__item--logout">
              <a href="#" @click.prevent="logout()">Выйти</a>
            </li>
          </ul>
        </div>
      </template>
    </header>
    <div class="main">
      <tabs></tabs>
      <div class="main-content">
        <router-view></router-view>
      </div>
    </div>
    <div v-if="isAuthorized" class="main-menu">
      <router-link :to="{ name: 'lots-list', params: {} }" class="main-menu__item main-menu__item--lots">
        <div class="main-menu__item-icon"></div>
        <div class="main-menu__item-name">
          Доноры
        </div>
      </router-link>
      <router-link :to="{ name: 'parts-list', params: {} }" class="main-menu__item main-menu__item--parts">
        <div class="main-menu__item-icon"></div>
        <div class="main-menu__item-name">
          Товары
        </div>
      </router-link>
      <router-link :to="{ name: 'reports', params: {} }" class="main-menu__item main-menu__item--reports">
        <div class="main-menu__item-icon"></div>
        <div class="main-menu__item-name">
          Отчеты
        </div>
      </router-link>
      <router-link :to="{ name: 'transactions', params: {} }" class="main-menu__item main-menu__item--transactions"
        :class="{ 'main-menu__item--notify': newTransactions.length }">
        <div class="main-menu__item-icon"></div>
        <div class="main-menu__item-name">
          Транзакции
        </div>
      </router-link>
      <router-link :to="{ name: 'orders-list', params: {} }" class="main-menu__item main-menu__item--orders">
        <div class="main-menu__item-icon"></div>
        <div class="main-menu__item-name">
          Заказы
        </div>
      </router-link>
      <router-link :to="{ name: 'logistic', params: {} }" class="main-menu__item main-menu__item--logistics">
        <div class="main-menu__item-icon"></div>
        <div class="main-menu__item-name">
          Логистика
        </div>
      </router-link>
      <router-link :to="{ name: 'user-list', params: {} }" class="main-menu__item main-menu__item--control">
        <div class="main-menu__item-icon"></div>
        <div class="main-menu__item-name">
          Управление
        </div>
      </router-link>
    </div>

    <textarea id="clipboard-textarea" style="opacity: 0; width: 1px; height: 1px; pointer-events: none;"
      readonly></textarea>
  </div>
</template>

<script>
import { ref, computed, onMounted, getCurrentInstance } from 'vue';
import { logOutSession, init } from "@/api";
import { socket } from "./modules/socket";
import useTransactions from "./composables/useTransactions";
// import request from "@/api/http";

import Tabs from "./components/Tabs.vue";

export default {
  name: "App",
  components: { Tabs },
  setup() {
    // временный хак до полноценного перехода к vue3
    const self = getCurrentInstance().proxy;
    const { newTransactions } = useTransactions();
    const profileDropdownVisible = ref(false);
    const logout = () => {
      localStorage.removeItem("authToken");
      self.$root.$store.commit("clearState");
      logOutSession();
      location.reload();
    }

    const appClass = computed(() => self.$root.$route.meta.appClass || '');
    const isAuthorized = computed(() => !!self.$root.$store.state.user.id);
    const userId = computed(() => self.$root.$store.state.user.id);
    onMounted(() => {
      init().then(res => {
        socket.connect(res.user.ws_token);
        socket.onMessage(data => {
          if (data.action == 'notify') {
            self.$root.alert(data.data.message);
          }
        });
        // setTimeout(()=>{
        //   request('/api/v1/test-websocket/').then(res => console.log(res));
        // }, 1000);
      });
    });
    return {
      profileDropdownVisible,
      logout,
      appClass,
      isAuthorized,
      userId,
      newTransactions,
    }
  },
};
</script>
