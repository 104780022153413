import request from "@/api/http";

export function loadReports (type = '', params = {}) {
	let url = `/api/v1/reports/${type}/?`;
	for (const key in params) {
		url += `${key}=${params[key]}&`;
	}
	return request(url);
}

export function loadReportsData (type = '') {
	let url = `/api/v1/reports/${type}/data/`;
	return request(url);
}

export function loadBalance () {
	return request('/api/v1/reports/balance/');
}

export function loadLots (params) {
    let url = '/api/v1/reports/lots/?';
    for (var key in params) {
        if (params[key]) {
            url += `${key}=${params[key]}&`;
        }
    }
    return request(url);
}

export function loadLotData () {
    let url = '/api/v1/reports/lots/data/';
    return request(url);
}
