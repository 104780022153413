import Vue from 'vue';
import App from './App.vue';
import 'ant-design-vue/dist/antd.css';
require('../node_modules/normalize.css/normalize.css');


require('alertifyjs/build/css/alertify.min.css');
require('alertifyjs/build/css/themes/default.min.css');
require('ant-design-vue/dist/antd.css');

import alertify from 'alertifyjs';
window.alertify = alertify;

require('./sass/main.sass');

Vue.config.productionTip = false;

import {
  DatePicker,
  Icon,
  Button,
  Select
} from "ant-design-vue";
Vue.use(DatePicker);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Select);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Vuex from 'vuex';
Vue.use(Vuex);
import store from './store';

import VueRouter from 'vue-router';
Vue.use(VueRouter);

Vue.directive('focus', {
  inserted(el) {
    el.focus()
  }
});

function pluralize(number, words) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return words[2];
  }
  n %= 10;
  if (n === 1) {
    return words[0];
  }
  if (n >= 2 && n <= 4) {
    return words[1];
  }
  return words[2];
}

Vue.filter('plural', (value, words) => pluralize(value, words));

Vue.filter('currencyFormat', (value, currency = 'RUB') => {
  if (isNaN(value)) {
    return value;
  }
  try {
    value = Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency
    }).format(+value);
  } catch (e) {
    console.error(e);
  }
  return value;
});

Vue.filter('phoneFormat', phone => {
  phone = phone.replace(/[^\d]/g, "");
  if (phone.length != 11) {
    return phone;
  }
  return phone.replace(/\d(\d{3})(\d{3})(\d{2})(\d{2})/, "+7 $1 $2 $3 $4");
});

Vue.component('form-photo', () => import('./components/FormPhoto'));
Vue.component('input-date', () => import('./components/InputDate'));
Vue.component('input-mask', () => import('./components/InputMask'));
Vue.component('input-counter', () => import('./components/InputCounter'));
Vue.component('input-file', () => import('./components/InputFile'));
Vue.component('select-searchable', () => import('./components/SelectSearchable'));
Vue.component('input-text-multi', () => import('./components/InputTextMulti'));
Vue.component('pagination', () => import('./components/Pagination'));
Vue.component('btn-menu', () => import('./components/BtnMenu'));
Vue.component('image-slider', () => import('./components/ImageSlider'));
Vue.component('context-menu', () => import('./components/ContextMenu'));
Vue.component('popup', () => import('./components/Popup'));
Vue.component('input-date-range', () => import('./components/ui/InputDateRange'));

Vue.component('lots-list', () => import('./components/Lots/LotsList'));

let routes = [{
    path: '/auth/',
    name: 'auth',
    component: () => import('./components/Login'),
    meta: {
      appClass: 'page-login',
    }
  },
  {
    path: '/profile/:id/',
    name: 'profile',
    component: () => import('./components/Profile/Profile'),
    props: true,
  },
  {
    path: '/orders/',
    name: 'orders-list',
    component: () => import('./components/Orders/OrdersList'),
    props: true,
  },
  {
    path: '/orders/:id/',
    name: 'order-detail',
    component: () => import('./components/Orders/OrderDetail'),
    props: true,
  },
  {
    path: '/control/',
    name: 'user-list',
    component: () => import('./components/Control/UserList'),
    props: true,
  },
  {
    path: '/control/users/add/',
    name: 'user-add',
    component: () => import('./components/Control/UserForm'),
    props: true,
  },
  {
    path: '/control/users/:id/',
    name: 'user-detail',
    component: () => import('./components/Control/UserDetail'),
    props: true,
  },
  {
    path: '/control/users/:id/edit/',
    name: 'user-edit',
    component: () => import('./components/Control/UserForm'),
    props: true,
  },
  {
    path: '/branch/new/',
    name: 'branch-new',
    component: () => import('./components/Branch/CreateBranchForm'),
  },
  {
    path: '/branch/edit/',
    name: 'branch-edit',
    component: () => import('./components/Branch/EditBranch'),
  },
  {
    path: '/lots/',
    name: 'lots-list',
    component: () => import('./components/Lots/LotsList'),
    props: {
      controls: ['pagination', 'search', 'filters', 'dupplicate', ],
      columns: ['pic', 'number', 'brand', 'model', 'year', 'price', 'parts_count', 'created_at', 'balance', ],
    },
  },
  {
    path: '/lots/add/',
    name: 'lot-add',
    component: () => import('./components/Lots/LotForm'),
    props: true,
  },
  {
    name: 'lot-detail',
    path: '/lots/:id/',
    component: () => import('./components/Lots/LotDetail'),
    props: true,
  },
  {
    name: 'lot-parts',
    path: '/lots/:lotId/parts/',
    component: () => import('./components/Lots/LotParts'),
    props: true,
  },
  {
    name: 'lot-edit',
    path: '/lots/:id/edit/',
    component: () => import('./components/Lots/LotForm'),
    props: true,
  },
  {
    name: 'lot-dupplicate',
    path: '/lots/:dupParentId/dupplicate/',
    component: () => import('./components/Lots/LotForm'),
    props: true,
  },
  {
    path: '/parts/',
    name: 'parts-list',
    component: () => import('./components/Parts/PartsList'),
    props: {
      controls: ['search', 'filters', 'dupplicate', 'excel-import', 'excel-export', 'add-to-cart', ],
      // columns : [ 'pic', 'number', 'model', 'branch', 'parts_count', 'created_at', ],
    }
  },
  {
    path: '/parts/add/',
    name: 'part-add',
    component: () => import('./components/Parts/PartForm'),
    props: true,
  },
  {
    path: '/parts/:partId-:lotId/add/',
    name: 'part-part-add',
    component: () => import('./components/Parts/PartForm'),
    props: true,
  },
  {
    path: '/lots/:lotId/parts/add/',
    name: 'lot-part-add',
    component: () => import('./components/Parts/PartForm'),
    props: true,
  },
  {
    path: '/parts/:id/',
    name: 'part-detail',
    component: () => import('./components/Parts/PartDetail'),
    props: true,
  },
  {
    path: '/parts/:id/sell/',
    name: 'part-sell',
    component: () => import('./components/Parts/PartSell'),
    props: true,
  },
  {
    path: '/parts/:id/shipment/',
    name: 'part-shipment',
    component: () => import('./components/Parts/PartShipment'),
    props: true,
  },
  {
    path: '/parts/:id/edit/',
    name: 'part-edit',
    component: () => import('./components/Parts/PartForm'),
    props: true,
  },
  {
    name: 'part-dupplicate',
    path: '/parts/:dupParentId/dupplicate/',
    component: () => import('./components/Parts/PartForm'),
    props: true,
  },
  {
    path: '/parts/:partId/parts/',
    name: 'part-parts',
    component: () => import('./components/Parts/PartParts'),
    props: true,
  },
  {
    // path: '/control/users/:investorId/:transactionType/',
    path: '/control/users/:investorId/',
    name: 'user-deposit',
    component: () => import('./components/Transactions/DepositForm'),
    props: true,
  },
  {
    path: '/control/users/:investorId/investment/',
    name: 'user-investment',
    component: () => import('./components/Transactions/InvestmentForm'),
    props: true,
  },
  {
    path: '/control/users/:investorId/withdrawal/',
    name: 'user-withdrawal',
    component: () => import('./components/Transactions/WithdrawalForm'),
    props: true,
  },
  {
    path: '/transactions/',
    name: 'transactions',
    redirect: {
      name: 'transactions-deposit'
    },
    component: () => import('./components/Transactions/Transactions'),
    props: true,
  },
  {
    path: '/transactions/deposit/',
    name: 'transactions-deposit',
    component: () => import('./components/Transactions/TransactionsList'),
    props: {
      transactionType: 'deposit'
    },
  },
  {
    path: '/transactions/replenishment/',
    name: 'transactions-replenishment',
    component: () => import('./components/Transactions/TransactionsList'),
    props: {
      transactionType: 'replenishment'
    },
  },
  {
    path: '/transactions/withdrawal/',
    name: 'transactions-withdrawal',
    component: () => import('./components/Transactions/TransactionsList'),
    props: {
      transactionType: 'withdrawal'
    },
  },
  {
    path: '/transactions/investments/',
    name: 'transactions-investments',
    component: () => import('./components/Transactions/TransactionsList'),
    props: {
      transactionType: 'investment'
    },
  },
  {
    path: '/transactions/expense/',
    name: 'transactions-expense',
    component: () => import('./components/Transactions/TransactionsList'),
    props: {
      transactionType: 'expense'
    },
  },
  {
    path: '/transactions/sales/',
    name: 'transactions-sales',
    component: () => import('./components/Transactions/TransactionsList'),
    props: {
      transactionType: 'sale'
    },
  },
  {
    path: '/transactions/prepayment/',
    name: 'transactions-prepayment',
    component: () => import('./components/Transactions/TransactionsList'),
    props: {
      transactionType: 'prepayment'
    },
  },
  {
    path: '/transactions/refund/',
    name: 'transactions-refund',
    component: () => import('./components/Transactions/TransactionsList'),
    props: {
      transactionType: 'refund'
    },
  },
  {
    path: '/logistic/',
    name: 'logistic',
    component: () => import('./components/Logistics/LogisticsList'),
    // props : { transactionType : 'logistic' },
  },
  {
    path: '/logistics/:id/',
    name: 'logistic-detail',
    component: () => import('./components/Logistics/LogisticDetail'),
    props: true,
  },
  {
    path: '/transactions/deposit/branch-:branchId/add/',
    name: 'branch-transaction-deposit-add',
    component: () => import('./components/Transactions/DepositForm'),
    props: true,
  },
  {
    path: '/transactions/withdrawal/add/',
    name: 'transaction-withdrawal-add',
    component: () => import('./components/Transactions/WithdrawalForm'),
    props: true,
  },
  {
    path: '/transactions/investment/add/',
    name: 'transaction-investment-add',
    component: () => import('./components/Transactions/InvestmentForm'),
    props: true,
  },
  {
    path: '/transactions/transfer/branch-:branchId/add/',
    name: 'branch-transaction-transfer-add',
    component: () => import('./components/Transactions/TransferForm'),
    props: true,
  },
  {
    path: '/transactions/transfer/add/',
    name: 'transaction-transfer-add',
    component: () => import('./components/Transactions/TransferForm'),
    props: true,
  },
  {
    path: '/transactions/expense/add/',
    name: 'transaction-expense-add',
    component: () => import('./components/Transactions/ExpenseForm'),
    props: true,
  },
  {
    path: '/transactions/:transactionType/add/',
    name: 'transaction-add',
    component: () => import('./components/Transactions/DepositForm'),
    props: true,
  },
  // {
  //     path: '/reports/',
  //     name: 'reports',
  //     component : () => import('./components/DevProcess'),
  // },
  {
    path: '/reports/',
    name: 'reports',
    redirect: {
      name: 'reports-balance'
    },
    props: true,
  },
  {
    path: '/reports/balance/',
    name: 'reports-balance',
    component: () => import('./components/Reports/Balance'),
    props: true,
  },
  {
    path: '/reports/income/',
    name: 'reports-income',
    component: () => import('./components/Reports/Reports'),
    props: {
      type: 'income'
    },
  },
  {
    path: '/reports/expense/',
    name: 'reports-expense',
    component: () => import('./components/Reports/Reports'),
    props: {
      type: 'expense'
    },
  },
  {
    path: '/reports/profit/',
    name: 'reports-profit',
    component: () => import('./components/Reports/Reports'),
    props: {
      type: 'branch-profit'
    },
  },
  {
    path: '/reports/sale/',
    name: 'reports-sale',
    component: () => import('./components/Reports/Reports'),
    props: {
      type: 'sale'
    },
  },
  {
    path: '/cart/',
    name: 'cart',
    component: () => import('./components/Cart/Cart'),
    props: true,
  },
  {
    path: '/export/',
    name: 'export-cart',
    component: () => import('./components/Cart/Export'),
  },
  {
    path: '/nomenclature/',
    name: 'nomenclature-list',
    component: () => import('./components/Nomenclature/NomenclatureList'),
    props: true,
  },
  {
    path: '/nomenclature/add/',
    name: 'nomenclature-add-form',
    component: () => import('./components/Nomenclature/NomenclatureForm'),
    props: true,
  },
  {
    path: '/nomenclature/add/category-:categoryId/',
    name: 'category-nomenclature-add-form--category',
    component: () => import('./components/Nomenclature/NomenclatureForm'),
    props: true,
  },
  {
    path: '/administration/',
    name: 'administration',
    component: () => import('./components/Administration/Administration'),
    props: true,
  },
  {
    path: '/administration/roles/',
    name: 'administration-roles',
    component: () => import('./components/Administration/Administration'),
    props: true,
  },
  {
    path: '/administration/roles/add/',
    name: 'administration-role-add',
    component: () => import('./components/Administration/RoleForm'),
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: "active",
});

new Vue({
  render: h => h(App),
  store,
  router,
  mounted() {
    this.$store.dispatch('init')
      .then(res => {
        if (+(res.user || {}).id) {
          if (this.$router.currentRoute.name == 'auth') {
            this.$router.push({
              name: 'lots-list'
            });
          }
        } else {
          if (this.$router.currentRoute.name != 'auth') {
            this.$router.push({
              name: 'auth'
            });
          }
        }
      });
  },
  computed: {
    currentSection() {
      let currentSection = '';
      if (this.$route.path.indexOf('/control') === 0) {
        currentSection = 'control';
      }
      if (this.$route.path.indexOf('/transactions') === 0) {
        currentSection = 'transactions';
      }
      if (this.$route.path.indexOf('/reports') === 0) {
        currentSection = 'reports';
      }
      return currentSection;
    },
  },
  methods: {
    inGroup(groupIds) {
      if (!groupIds) {
        return false;
      }
      if (!this.$store.state.user) {
        return false;
      }
      if (!this.$store.state.user.roles) {
        return false;
      }
      let userGroups = this.$store.state.user.roles.map(r => +r.id);
      if (typeof (groupIds) != 'object') {
        groupIds = [groupIds];
      }
      return !!groupIds.find(id => ~userGroups.indexOf(+id));
    },
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
      } else {
        let clipboardTextarea = document.querySelector('#clipboard-textarea');
        if (clipboardTextarea) {
          clipboardTextarea.value = text;
          clipboardTextarea.select();
          document.execCommand('copy');
        }
      }
    },
    request(endpoint, options = {}) {
      return new Promise(resolve => {
        fetch(
            'https://trucksrazbor.ru' + endpoint,
            Object.assign({
              headers: {
                Authorization: `Bearer ${this.$store.state.authToken}`,
              }
            }, options)
          )
          .then(res => res.json())
          .then(res => {
            resolve(res);
          });
      });
    },
    loadBrands() {
      return new Promise(resolve => {
        this.request('/api/v1/marks/')
          .then(res => {
            resolve(res);
          });
      });
    },
    currencyFormat(value, currency = 'RUB') {
      if (!+value) {
        return value;
      }
      try {
        value = Intl.NumberFormat('ru-RU', {
          style: 'currency',
          currency
        }).format(+value);
      } catch (e) {
        console.error(e);
      }
      return value;
    },
    alert(message, status = 'success') {
      alertify.notify(message, status);
    },
    addTab(tab) {
      this.$store.dispatch('addTab', tab);
    },
    removeTab(tab) {
      this.$store.dispatch('removeTab', tab);
    },
  },
  watch: {
    currentSection(currentSection) {
      let tabs = this.$store.state.tabs.filter(tab => tab.closable || tab.name == 'Корзина' || tab.name == 'Корзина предоплаты');
      if (currentSection == 'control') {
        tabs.push(...[{
          name: 'Пользователи',
          route: {
            name: 'user-list',
          },
        }, ]);
      }
      if (currentSection == 'reports') {
        tabs.push(...[{
            name: 'Продажи',
            route: {
              name: 'reports-sale',
            },
          },
          {
            name: 'Доходы',
            route: {
              name: 'reports-income',
            },
          },
          {
            name: 'Расходы',
            route: {
              name: 'reports-expense',
            },
          },
          {
            name: 'Прибыль',
            route: {
              name: 'reports-profit',
            },
          },
          {
            name: 'Баланс',
            route: {
              name: 'reports-balance',
            },
          },
        ]);
      }
      if (currentSection == 'transactions') {
        tabs.push(...[{
            name: 'Пополнение инвесторов',
            route: {
              name: 'transactions-deposit',
            },
          },
          {
            name: 'Пополнение филиалов',
            route: {
              name: 'transactions-replenishment',
            },
          },
          {
            name: 'Вывод д/с',
            route: {
              name: 'transactions-withdrawal',
            },
          },
          {
            name: 'Инвестиции',
            route: {
              name: 'transactions-investments',
            },
          },
          {
            name: 'Расходы',
            route: {
              name: 'transactions-expense',
            },
          },
          {
            name: 'Продажи',
            route: {
              name: 'transactions-sales',
            },
          },
          {
            name: 'Предоплата',
            route: {
              name: 'transactions-prepayment',
            },
          },
          {
            name: 'Возвраты',
            route: {
              name: 'transactions-refund',
            },
          },
        ]);
      }
      this.$store.dispatch('setTabs', tabs);
    },
  },
}).$mount('#app')