import Vue from 'vue';
import Vuex from 'vuex';
import {
    addToCart,
    loadCart
} from '@/api';
const API_URL = process.env.API_HOST ? process.env.API_HOST : 'https://trucksrazbor.ru';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        wsToken: '',
        authToken: localStorage ? localStorage.getItem('authToken') : '',
        user: {},
        cart: {
            items: [],
            totalSum: 0,
            loaded: false,
        },
        tabs: [],
        formStates: {},
        lastNomenclatureAddedId: '',
        exportedParts: [],
    },
    mutations: {
        setWsToken(state, token) {
            state.wsToken = token;
        },
        addExportedParts(state, data) {
            state.exportedParts.push(...data)
        },
        removeExportedPart(state, part) {
            state.exportedParts.splice(state.exportedParts.findIndex((item) => item.part_level == part.part_level && item.id == part.id), 1)
        },
        clearExportedParts(state) {
            state.exportedParts = []
        },
        setUserData(state, data) {
            state.user = data || {};
        },
        setCart(state, data) {
            data = data || {};
            data.loaded = true;
            state.cart = data;
        },
        setTabs(state, tabs) {
            state.tabs = tabs || [];
        },
        setAuthToken(state, token) {
            state.authToken = token;
            localStorage ? localStorage.setItem('authToken', token) : null;
        },
        setFormState(state, {
            key,
            values
        }) {
            state.formStates[key] = values;
        },
        setLastNomenclatureAddedId(state, id) {
            state.lastNomenclatureAddedId = id;
        },
        clearState(state) {
            state.authToken = '';
            state.user = {};
            state.cart = {
                items: [],
                totalSum: 0,
                loaded: false,
            };
            state.tabs = [];
            state.formStates = {};
            state.lastNomenclatureAddedId = '';
        }
    },
    actions: {
        setWsToken({
            commit
        }, token) {
            commit('setWsToken', token);
        },
        setLastNomenclatureAddedId({
            commit
        }, id) {
            commit('setLastNomenclatureAddedId', id);
        },
        setFormState({
            commit
        }, {
            key,
            values
        }) {
            commit('setFormState', {
                key,
                values
            });
        },
        setTabs({
            commit
        }, tabs) {
            commit('setTabs', tabs);
        },
        addTab({
            dispatch,
            state
        }, tab) {
            let tabs = state.tabs;
            for (var i = 0; i < tabs.length; i++) {
                if (tabs[i].route.name == tab.route.name && (tabs[i].route.params || {}).id == (tab.route.params || {}).id) {
                    return;
                }
            }
            tabs.push(tab);
            dispatch('setTabs', tabs);
        },
        removeTab({
            dispatch,
            state
        }, tab) {
            let tabs = state.tabs;
            let k = null;
            for (var i = 0; i < tabs.length; i++) {
                if (tabs[i].route.name == tab.route.name && (tabs[i].route.params || {}).id == (tab.route.params || {}).id) {
                    k = i;
                    if (typeof (tabs[i].onClose) == 'function') {
                        tabs[i].onClose();
                    }
                    break;
                }
            }
            if (k !== null) {
                tabs.splice(k, 1);
            }
            dispatch('setTabs', tabs);
        },
        setAuthToken({
            commit
        }, token) {
            commit('setAuthToken', token);
        },
        addToCart({
            dispatch
        }, data = {}) {
            return new Promise(resolve => {
                addToCart(data).then(res => {
                    dispatch('loadCart');
                    resolve(res);
                });
            });
        },
        loadCart({
            commit,
            dispatch
        }) {
            return new Promise((resolve) => {
                loadCart().then(res => {
                    if ((res.items || []).length) {
                        dispatch('addTab', {
                            name: 'Корзина',
                            route: {
                                name: 'cart',
                            },
                            onClose() {

                            },
                        });
                    } else {
                        dispatch('removeTab', {
                            route: {
                                name: 'cart'
                            }
                        });
                    }
                    commit('setCart', res);
                    resolve(res);
                });
            });
        },
        init({
            dispatch,
            commit
        }) {
            return new Promise((resolve) => {
                dispatch('request', '/api/v1/init/', {
                        redirect: 'manual',
                    })
                    .then(res => {
                        if (res.user) {
                            commit('setUserData', res.user);
                            dispatch('loadCart');
                        }
                        resolve(res);
                    });
            });
        },
        /* eslint-disable no-unused-vars */
        request({
            state
        }, endpoint, options = {}) {
            endpoint = API_URL + endpoint;
            return new Promise((resolve, reject) => {
                fetch(
                        endpoint,
                        Object.assign({
                            headers: {
                                Authorization: `Bearer ${state.authToken}`,
                                Accept: 'application/json',
                            }
                        }, options)
                    )
                    .then(res => {
                        if (res.status != 200) {
                            return {
                                status: res.ok,
                                httpStatus: res.status,
                                redirected: res.redirected,
                            };
                        }
                        return res.json();
                    })
                    .then(res => {
                        resolve(res);
                    });
            });
        },
        /* eslint-enable no-unused-vars */
    }
})