<template>
  <div class="tabs">
    <div class="tabs__header">
      <div v-for="(tab, tabKey) in tabs" :key="tabKey" class="tabs__item" :class="[
        `tabs__item--${tab.route.name}`,
        {
          'tabs__item--notify': ~tabsNotifyRouteNames.indexOf(tab.route.name),
          'card-refund': isRefund && tab.route.name == 'cart',
          closable: tab.closable,
          active:
            tab.route.name == $route.name &&
            (tab.route.params || {}).id == ($route.params || {}).id,
        },
      ]">
        <router-link :to="tab.route" class="tabs__item-name">
          <template v-if="tab.route.name == 'cart' && isRefund">Корзина возврата</template>
          <template v-else-if="tab.route.name == 'cart' && isPrepay">Корзина предоплаты</template>
          <template v-else-if="tab.route.name == 'cart'">Корзина</template>
          <template v-else>{{ tab.name }}</template>
          {{ tab.route.code }}
        </router-link>
        <a v-if="tab.closable" href="#" @click.prevent="close(tab)" class="tabs__item-close"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, computed } from 'vue';
import useTransactions from "../composables/useTransactions";

export default {
  name: "Tabs",
  setup() {
    const self = getCurrentInstance().proxy;

    const { newTransactions } = useTransactions();

    const close = tab => self.$root.removeTab(tab);
    const tabs = computed(() => self.$root.$store.state.tabs || []);
    const cartType = computed(() => self.$root.$store.state.cart.cart_type);
    const isRefund = computed(() => self.$root.cartType == "refund");
    const isPrepay = computed(() => self.$root.cartType == "prepayment");

    const tabsNotifyRouteNames = computed(() => {
      let tabsNotifyRouteNames = {};
      for (var i = 0; i < newTransactions.value.length; i++) {
        tabsNotifyRouteNames['transactions-' + newTransactions.value[i].type] = 'transactions-' + newTransactions.value[i].type;
      }
      return Object.keys(tabsNotifyRouteNames);
    });

    return {
      close,
      tabs,
      cartType,
      isRefund,
      isPrepay,
      tabsNotifyRouteNames,
    }
  }
};
</script>

<style lang="sass" scoped>
.tabs
    user-select: none
    &__header
        display: flex
        overflow-x: auto
        padding: 0 12px
        scrollbar-width: none
        &::-webkit-scrollbar
            display: none
    &__item
        position: relative
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
        background-color: #CED4DA
        height: 40px
        padding: 0 16px
        border: 1px solid #FFFFFF
        border-radius: 4px 4px 0 0
        &.active
            background-color: #F7F7F7
        &.closable
            padding-right: 32px
        &-name
            position: relative
            font-size: 14px
            font-weight: 500
            white-space: nowrap
            color: #152536
            overflow-x: hidden
        &-close
            cursor: pointer
            position: absolute
            display: block
            right: 10px
            background-image: url('../assets/cross.svg')
            background-repeat: no-repeat
            background-size: contain
            background-position: center
            width: 12px
            height: 12px
        &--cart
            background-color: #AAF491
        &--notify
            .tabs__item-name
                &::after
                    display: block
                    position: absolute
                    top: 0
                    right: -4px
                    content: ''
                    background-color: red
                    border-radius: 50%
                    width: 8px
                    height: 8px
.card-refund
  background-color: var(--color-red)
  & .tabs__item-name
    max-width: none
</style>
