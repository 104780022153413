var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tabs__header"},_vm._l((_vm.tabs),function(tab,tabKey){return _c('div',{key:tabKey,staticClass:"tabs__item",class:[
      `tabs__item--${tab.route.name}`,
      {
        'tabs__item--notify': ~_vm.tabsNotifyRouteNames.indexOf(tab.route.name),
        'card-refund': _vm.isRefund && tab.route.name == 'cart',
        closable: tab.closable,
        active:
          tab.route.name == _vm.$route.name &&
          (tab.route.params || {}).id == (_vm.$route.params || {}).id,
      },
    ]},[_c('router-link',{staticClass:"tabs__item-name",attrs:{"to":tab.route}},[(tab.route.name == 'cart' && _vm.isRefund)?[_vm._v("Корзина возврата")]:(tab.route.name == 'cart' && _vm.isPrepay)?[_vm._v("Корзина предоплаты")]:(tab.route.name == 'cart')?[_vm._v("Корзина")]:[_vm._v(_vm._s(tab.name))],_vm._v(" "+_vm._s(tab.route.code)+" ")],2),(tab.closable)?_c('a',{staticClass:"tabs__item-close",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.close(tab)}}}):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }