class Socket {
    ws = null;
    token = '';
    subscribers = [];

    connect(token) {
        this.token = token;
        this.ws = new WebSocket('wss://trucksrazbor.ru/socket?authorization=' + this.token);
        setInterval(() => {
            this.send('ping');
        }, 30000);
        this.ws.addEventListener('message', event => {
            for (var i = 0; i < this.subscribers.length; i++) {
                if (typeof (this.subscribers[i]) == 'function') {
                    let data = {};
                    try {
                        data = JSON.parse(event.data) || {};
                    } catch (e) {
                        data = {};
                    }
                    this.subscribers[i](data);
                }
            }
        });
    }

    onMessage(callback) {
        this.subscribers.push(callback);
    }

    send(data) {
        if (typeof (data) != 'string') {
            data = JSON.stringify(data);
        }
        this.ws.send(data);
    }

}

const socket = new Socket;

export {
    socket
};