import store from "@/store";

const API_URL = process.env.API_HOST ? process.env.API_HOST : 'https://trucksrazbor.ru';

export default function request(endpoint, options = {}) {
	endpoint = API_URL + endpoint;
	return new Promise((resolve) => {
		fetch(
				endpoint,
				Object.assign({
					headers: {
						Authorization: `Bearer ${store.state.authToken}`,
						Accept: 'application/json',
					}
				}, options)
			)
			.then(res => res.json())
			.then(res => {
				resolve(res);
			});
	});
}

export function requestFile(endpoint, options = {}) {
	endpoint = API_URL + endpoint;
	return new Promise((resolve) => {
		fetch(
				endpoint,
				Object.assign({
					headers: {
						Authorization: `Bearer ${store.state.authToken}`,
						Accept: 'application/json',
					}
				}, options)
			)
			.then(async (res) => ({
				body: await res.blob(),
				disposition: res.headers.get('Content-Disposition')
			}))
			.then(res => {
				resolve(res);
			});
	});
}